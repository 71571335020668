import { useFormik } from 'formik';
import * as yup from 'yup';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import CookieConsent from 'react-cookie-consent';
import { Auth } from 'aws-amplify';

import NotificationService from '@/components/notification-service';
import LogInForm from '@/components/auth/log-in-form';
import Sms2faForm from '@/components/auth/sms-2fa-form';
import UserNotEnabled from '@/components/auth/user-not-enabled';
import NoUserPermissions from '@/components/auth/no-user-permission';
import EmailConfirmation from '@/components/auth/email-confirmation';
import ResetPassStep0 from '@/components/auth/reset-pass-step-0';
import ResetPassStep1 from '@/components/auth/reset-pass-step-1';
import NewPasswordForm from '@/components/auth/new-password-form';
import EnterNameForm from '@/components/auth/enter-name-form';
import TermsAndConditions from '@/components/user/terms-and-conditions';
import { LOGIN_STATES } from '@/constants';
import { AppContext } from '@/utils/app-context';
import reducerList from '@/utils/reducer-list';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import { useStore } from '@/hooks';
import { Col, Container, Row } from 'react-bootstrap';
import { LogoImg } from '@/components/branding/logo-img';
import cx from 'clsx';

import LoginSvg from '@/assets/login/login.svg';
import globalStyles from '@/styles/globals.module.scss';
import styles from '@/pages/index.module.scss';
import { getCurrentYear } from '@/utils/helpers';

import { Broker } from '@/models';

import { CURRENT_BROKER_ID } from '@/enums/brokers';
import { isInsuredSubdomain } from '@/utils/regexes';

import NonProdMarker from '@/components/outside-prod-marker';

const schema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
  email: yup.string().email().required(),
  newPassword: yup.string(),
  password: yup.string().required(),
  firstName: yup.string(),
  lastName: yup.string(),
});

export const getServerSideProps = async ({ req, query }) => {
  const broker = await Broker.findOne(
    { id: CURRENT_BROKER_ID },
    { _id: 0, __v: 0, createdAt: 0, updatedAt: 0, 'divisions._id': 0 }
  ).lean();

  // check the host header to see if we need to enable the branding
  const host = req?.headers.host;
  broker.branding.isBranded =
    Object.values(broker.branding).some(v => !!v) &&
    isInsuredSubdomain.test(host);

  return {
    props: { broker },
  };
};

export default function Home({ initialState, broker }) {
  const router = useRouter();
  const [, dispatch] = useContext(AppContext);
  const [{ user: loggedInUser }, setStore] = useStore();
  const [mostRecentUser, setMostRecentUser] = useState();
  const [state, setState] = useState(initialState || LOGIN_STATES.INITIAL);
  const [signUpEmail, setSignUpEmail] = useState('');

  const { branding } = broker;
  const { isBranded } = branding;

  useEffect(() => {
    setStore({ broker });
  }, [broker, setStore]);

  async function navigateToHomePage(user) {
    const homePageLink = user.accessKeys.CERTIFICATES.ACCESS
      ? { pathname: '/home/[gridId]', query: { gridId: 'recent' } }
      : '/inbound/inbound-certificate-layout';

    await router.push(homePageLink);
  }

  useEffect(() => {
    if (loggedInUser?.loginCode) {
      setState(loggedInUser?.loginCode);
      return;
    }
  }, [loggedInUser]);

  // After we have finished authenticating with AWS, we log in to our backend
  async function logIn() {
    const result = await FrontendHttpClient.post('/api/login');

    if (result.data.code === LOGIN_STATES.USER_NOT_ENABLED) {
      return LOGIN_STATES.USER_NOT_ENABLED;
    }

    if (!result.data.user?.groupId) {
      return LOGIN_STATES.NO_USER_PERMISSIONS;
    }

    dispatch({
      type: reducerList.SET_USER,
      payload: result.data.user,
    });

    if (result.data.user.firstName === '' || result.data.user.lastName === '') {
      return LOGIN_STATES.ENTER_NAME;
    }

    if (result.data.code === LOGIN_STATES.HAS_NOT_READ_TERMS) {
      return LOGIN_STATES.HAS_NOT_READ_TERMS;
    }

    await navigateToHomePage(result.data.user);

    return LOGIN_STATES.LOGGED_IN;
  }

  async function signIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        NotificationService.info('New password required');
        return LOGIN_STATES.NEW_PASSWORD_REQUIRED;
      }

      if (user.challengeName === 'SMS_MFA') {
        NotificationService.info(
          `${user.challengeParam.CODE_DELIVERY_DELIVERY_MEDIUM} sent to ${user.challengeParam.CODE_DELIVERY_DESTINATION}`
        );

        setMostRecentUser(user);
        return LOGIN_STATES.SMS_2FA;
      }

      return logIn();
    } catch (error) {
      console.log(`signIn error:`, error);
      if (error.code === 'UserNotConfirmedException') {
        // User is not confirmed, so they need to confirm their email
        NotificationService.info(error.message);

        // Set the email so we can use it in the email confirmation form
        setSignUpEmail(username);

        return LOGIN_STATES.CONFIRM_EMAIL;
      }

      NotificationService.error(
        'Sign in failed. Please check email/password and try again'
      );
    }

    return LOGIN_STATES.INITIAL;
  }

  async function userReadTermsAndConditions() {
    await router.push({
      pathname: '/home/[gridId]',
      query: { gridId: 'recent' },
    });
    return LOGIN_STATES.LOGGED_IN;
  }

  async function changePassword(username, newPassword, oldPassword) {
    try {
      const user = await Auth.signIn(username, oldPassword);

      await Auth.completeNewPassword(user, newPassword);

      return logIn();
    } catch (error) {
      NotificationService.error(error.message);
      throw error;
    }
  }

  const form = useFormik({
    validationSchema: schema,
    onSubmit: async (submitValues, { setSubmitting }) => {
      try {
        await FrontendHttpClient.get('/api/login');
        if (state === LOGIN_STATES.NEW_PASSWORD_REQUIRED) {
          const newState = await changePassword(
            submitValues.email,
            submitValues.newPassword,
            submitValues.password
          );

          setState(newState);
        } else {
          if (state === LOGIN_STATES.ENTER_NAME) {
            await FrontendHttpClient.put('/api/v2/users/profile', {
              firstName: submitValues.firstName,
              lastName: submitValues.lastName,
              termsAccepted: true,
            });

            navigateToHomePage(loggedInUser);
            return;
          }

          const newState = await signIn(
            submitValues.email,
            submitValues.password
          );
          setState(newState);
        }
      } catch (error) {
        if (error.response?.data?.message) {
          NotificationService.error(error.response.data.message);
        }
      } finally {
        setSubmitting(false);
      }
    },
    initialValues: {
      confirmPassword: '',
      email: signUpEmail || '',
      newPassword: '',
      resetPassword: '',
      password: '',
      phoneNumber: '',
      signInEnabled: true,
      confirmationCode: '',
      verificationCode: '',
    },
  });

  const { handleSubmit } = form;

  const renderLoginState = () => {
    switch (state) {
      case LOGIN_STATES.HAS_NOT_READ_TERMS:
        return (
          <TermsAndConditions confirmReadTerms={userReadTermsAndConditions} />
        );

      case LOGIN_STATES.USER_NOT_ENABLED:
        return <UserNotEnabled />;

      case LOGIN_STATES.NO_USER_PERMISSIONS:
        return <NoUserPermissions />;

      case LOGIN_STATES.INITIAL:
        return <LogInForm form={form} setState={setState} />;

      case LOGIN_STATES.CONFIRM_EMAIL:
        return (
          <EmailConfirmation
            form={form}
            setState={setState}
            signUpEmail={signUpEmail}
          />
        );

      case LOGIN_STATES.SMS_2FA:
        return (
          <Sms2faForm
            form={form}
            setState={setState}
            logIn={logIn}
            mostRecentUser={mostRecentUser}
          />
        );

      case LOGIN_STATES.RESET_PASSWORD_ENTER_EMAIL:
        return <ResetPassStep0 form={form} setState={setState} />;

      case LOGIN_STATES.RESET_PASSWORD:
        return <ResetPassStep1 form={form} setState={setState} />;

      case LOGIN_STATES.NEW_PASSWORD_REQUIRED:
        return <NewPasswordForm form={form} />;

      case LOGIN_STATES.ENTER_NAME:
        return <EnterNameForm form={form} />;

      default:
        return null;
    }
  };

  return (
    <Container fluid style={{ height: '100%' }}>
      <Row style={{ height: '100%', overflow: 'hidden' }}>
        <Col className={styles.leftColumn}>
          <div className={styles.logoContainer}>
            <LogoImg
              className={globalStyles.image}
              src="/logo-dark-1x.png"
              srcSet="/logo-dark-2x.png 2x"
              alt="Certificate Hero"
            />
          </div>
          <NonProdMarker />
          <>
            {
              <Form
                id="loginForm"
                className={styles.formContainer}
                onSubmit={handleSubmit}
              >
                {renderLoginState()}
              </Form>
            }

            <CookieConsent location="top" overlay buttonId="acceptCookieButton">
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </>
          <div className={cx(isBranded ? '' : 'd-none')}>
            <span className="text-uppercase p-1">Powered By </span>
            <img src="/logo-dark-1x.png" alt="Certificate Hero" width="100" />
          </div>
        </Col>
        <Col className={cx(styles.loginColumn, isBranded ? 'd-none' : '')}>
          <div className={styles.loginHeaderContainer}>
            <h3 className={styles.loginHeader}>
              Revolutionizing the Way you Manage Certificates of Insurance
            </h3>
          </div>
          <LoginSvg className={styles.loginImage} alt="Certificate Hero" />
          <p className={cx('text-muted', styles.copyright)}>
            © {getCurrentYear()} Certificate Hero, Inc.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
