/**
 *
 * Function to load CDN Js scripts asyncrnously
 *
 * This adds a script tag to the header of the document if it doesn't exist already
 *
 * @param {url} src - url for the javascript CDN
 * @returns
 */
export const loadCDN = src =>
  // eslint-disable-next-line consistent-return
  new Promise((resolve, reject) => {
    if (document.querySelector(`head > script[src="${src}"]`) !== null) {
      return resolve();
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    script.onload = resolve;
    script.onerror = reject;
  });

export const loadCSSfromCDN = async cdnUrl => {
  if (document.querySelector(`head > link[href="${cdnUrl}"]`) !== null) {
    return;
  }
  const importCSS = window.document.createElement('link');
  importCSS.setAttribute('rel', 'stylesheet');
  importCSS.setAttribute('href', cdnUrl);
  document.head.appendChild(importCSS);
};
