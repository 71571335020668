import Button from 'react-bootstrap/Button';
import { Modal } from '@/components/modal';
import PdfViewerReadOnlyTextLayer from '@/components/pdfviewer/pdf-viewer-readonly-text-layer';
import { CURRENT_EULA_PATH } from '@/constants';
import styles from './styles.module.scss';

/**
 * @param {object} params
 * @param {boolean} params.open
 * @param {() => void} params.hide
 */
export const TermsAndConditionsModal = ({ open, hide }) => {
  return (
    <Modal size="lg" show={open} onHide={hide}>
      <Modal.Body>
        <PdfViewerReadOnlyTextLayer filePath={CURRENT_EULA_PATH} />
        <Button className={styles.button} id="confirmButton" onClick={hide}>
          Confirm
        </Button>
      </Modal.Body>
    </Modal>
  );
};
