import TextInput from '@/components/text-input';
import { ButtonPrimary } from '@/components/button';
import globalStyles from '@/styles/globals.module.scss';
import styles from '../styles.module.scss';

/**
 * @typedef {object} NewPasswordParams
 * @property {string} newPassword
 * @property {string} confirmPassword
 */
/**
 * @param {object} props
 * @param {import('formik').FormikProps<NewPasswordParams>} props.form
 */
const NewPasswordForm = ({ form }) => {
  if (!form) {
    return null;
  }

  const { values, touched, errors, handleChange, isSubmitting } = form;

  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}> Enter New Password</p>

      <TextInput
        label="New Password"
        type="password"
        placeholder="Password"
        name="newPassword"
        value={values.newPassword}
        isInvalid={touched.newPassword && !!errors.newPassword}
        error={errors.newPassword}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <TextInput
        label="Confirm Password"
        type="password"
        placeholder="Confirm Password"
        name="confirmPassword"
        value={values.confirmPassword}
        isInvalid={touched.confirmPassword && !!errors.confirmPassword}
        error={errors.confirmPassword}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <ButtonPrimary
        className={styles.submitButton}
        type="submit"
        form="loginForm"
        disabled={isSubmitting}
      >
        Set
      </ButtonPrimary>
    </div>
  );
};

export default NewPasswordForm;
