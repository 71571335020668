import { Container, Row, Col } from 'react-bootstrap';
import cx from 'clsx';

import LoginSvg from '@/assets/login/login.svg';
import globalStyles from '@/styles/globals.module.scss';
import styles from '@/pages/index.module.scss';
import { getCurrentYear } from '@/utils/helpers';
import { LogoImg } from '@/components/branding/logo-img';
import { useStore } from '@/hooks';

export default function LoginLayout({ children }) {
  const [{ broker }] = useStore();
  const { isBranded } = broker;

  return (
    <>
      <Container fluid style={{ height: '100%' }}>
        <Row style={{ height: '100%', overflow: 'hidden' }}>
          <Col className={styles.leftColumn}>
            <div className={styles.logoContainer}>
              <LogoImg
                className={globalStyles.image}
                src="/logo-dark-1x.png"
                srcSet="/logo-dark-2x.png 2x"
                alt="Certificate Hero"
              />
            </div>
            {children}
            <div className={cx(isBranded ? '' : 'd-none')}>
              <span className="text-uppercase p-1">Powered By </span>
              <img src="/logo-dark-1x.png" alt="Certificate Hero" width="100" />
            </div>
          </Col>
          <Col className={cx(styles.loginColumn, isBranded ? 'd-none' : '')}>
            <div className={styles.loginHeaderContainer}>
              <h3 className={styles.loginHeader}>
                Revolutionizing the Way you Manage Certificates of Insurance
              </h3>
            </div>
            <LoginSvg className={styles.loginImage} alt="Certificate Hero" />
            <p className={cx('text-muted', styles.copyright)}>
              © {getCurrentYear()} Certificate Hero, Inc.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
