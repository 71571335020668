import styles from '../styles.module.scss';

const UserNotEnabled = () => {
  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}>
        User is not enabled. Ask an administrator to enable
      </p>
    </div>
  );
};

export default UserNotEnabled;
