import { useRouter } from 'next/router';
import cx from 'clsx';

import { LOGIN_STATES } from '@/constants';
import TextInput from '@/components/text-input';
import { ButtonCreate, ButtonLink } from '@/components/button';
import NotificationService from '@/components/notification-service';
import globalStyles from '@/styles/globals.module.scss';
import styles from '../styles.module.scss';

/**
 * @typedef {object} LoginParams
 * @property {string} email
 * @property {string} password
 */
/**
 * @param {object} props
 * @param {import('formik').FormikProps<LoginParams>} props.form
 * @param {(string) => void} props.setState
 */
const LogInForm = ({ form, setState }) => {
  const router = useRouter();

  if (!form) {
    return null;
  }

  const { values, touched, errors, handleChange, isSubmitting } = form;

  /**
   *
   * @param {{password?: string, email?: string}} submitErrors
   * @param {{password?: string, email?: string}} submitValues
   */
  const displayLoginSubmitErrors = (submitErrors, submitValues) => {
    if (!submitValues.email || submitErrors.email) {
      NotificationService.error('Please enter a valid email.');
    } else if (!submitValues.password || submitErrors.password) {
      NotificationService.error('Password is required.');
    }
  };

  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}> Sign In</p>
      {router.query.isSessionExpired ? (
        <div className={styles.sessionExpiredContainer}>
          <p className={styles.sessionExpiredText}>
            Session Expired. Please sign in again.
          </p>
        </div>
      ) : null}

      <TextInput
        name="email"
        label="Email"
        id="formBasicEmail"
        placeholder="Enter email"
        value={values.email}
        onChange={handleChange}
        isInvalid={touched.email && !!errors.email}
        error={errors.email}
        labelClassName={styles.label}
        containerClassName={globalStyles.inputContainer}
      />

      <TextInput
        name="password"
        label="Password"
        type="password"
        id="formBasicPassword"
        placeholder="Password"
        value={values.password}
        onChange={handleChange}
        isInvalid={touched.password && !!errors.password}
        error={errors.password}
        labelClassName={styles.label}
        containerClassName={cx(
          globalStyles.inputContainer,
          styles.passwordInput
        )}
      />

      <div className={cx(globalStyles.row, styles.forgotContainer)}>
        <ButtonLink
          id="resetPasswordButton"
          className={styles.leftButtonLink}
          onClick={async () => {
            setState(LOGIN_STATES.RESET_PASSWORD_ENTER_EMAIL);
          }}
        >
          Forgot Password?
        </ButtonLink>
      </div>

      <ButtonCreate
        type="submit"
        id="signInButton"
        pending={isSubmitting}
        onClick={() => displayLoginSubmitErrors(errors, values)}
        containerClassName={styles.submitButton}
      >
        Sign In
      </ButtonCreate>
    </div>
  );
};

export default LogInForm;
