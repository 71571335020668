import { Auth } from 'aws-amplify';

import TextInput from '@/components/text-input';
import { ButtonPrimary, ButtonLink } from '@/components/button';
import NotificationService from '@/components/notification-service';
import { LOGIN_STATES } from '@/constants';
import globalStyles from '@/styles/globals.module.scss';
import Label from '@/components/label';
import styles from '../styles.module.scss';

/**
 * @typedef {object} Step0Params
 * @property {string} email
 */
/**
 * @param {object} props
 * @param {import('formik').FormikProps<Step0Params>} props.form
 * @param {(string) => void} props.setState
 */
const ResetPassStep0 = ({ form, setState }) => {
  if (!form) {
    return null;
  }

  const { values, touched, errors, handleChange, isSubmitting, setTouched } =
    form;

  const startPasswordReset = async username => {
    try {
      const data = await Auth.forgotPassword(username);

      NotificationService.info(
        `${data.CodeDeliveryDetails.DeliveryMedium} sent to ${data.CodeDeliveryDetails.Destination}`
      );

      return LOGIN_STATES.RESET_PASSWORD;
    } catch (err) {
      NotificationService.error(err.message);

      return LOGIN_STATES.INITIAL;
    }
  };

  const handleSubmit = async () => {
    setTouched({ email: true });
    if (!values.email) {
      NotificationService.info('Need to enter email to reset password');
      return;
    }
    if (!errors.email) {
      const newState = await startPasswordReset(values.email);
      setState(newState);
    }
  };
  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}>Reset Password</p>

      <TextInput
        name="email"
        label="Email"
        type="email"
        placeholder="Enter email"
        value={values.email}
        isValid={touched.email && !errors.email}
        isInvalid={!!errors.email}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />
      {touched.email && errors.email && (
        <Label className={styles.error}>{errors.email}</Label>
      )}
      <ButtonPrimary
        className={styles.submitButton}
        form="loginForm"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        Send Code
      </ButtonPrimary>

      <div className={styles.signupLabel}>
        <ButtonLink
          id="backToInitialButton"
          className={styles.leftButtonLink}
          onClick={() => {
            setState(LOGIN_STATES.INITIAL);
          }}
        >
          Back
        </ButtonLink>
      </div>
    </div>
  );
};

export default ResetPassStep0;
