import Button from 'react-bootstrap/Button';
import FrontendHttpClient from '@/utils/http-handler/frontend-http-client';
import LoginLayout from '@/layout/login-layout/login-layout';
import { CURRENT_EULA_PATH } from '@/constants';
import dynamic from 'next/dynamic';
import styles from './terms-and-conditions.module.scss';

const PdfViewerReadOnlyTextLayer = dynamic(
  () => import('@/components/pdfviewer/pdf-viewer-readonly-text-layer'),
  { ssr: false }
);

/**
 * Show current EULA to the user
 * @param {object} params
 * @param {boolean} params.visibility
 * @param {Function} params.confirmReadTerms
 */
export default function TermsAndConditions({
  confirmReadTerms,
  visibility = true,
}) {
  async function submitHasRead() {
    await FrontendHttpClient.post(`/api/users/terms-and-conditions`);
    return confirmReadTerms();
  }

  function termsVisibility() {
    return visibility ? '' : styles.hidden;
  }

  return (
    <div className={`${styles.readerOuterContainer} ${termsVisibility()}`}>
      <div className={styles.readerInnerContainer}>
        <PdfViewerReadOnlyTextLayer
          filePath={CURRENT_EULA_PATH}
          height="calc(75vh - 100px)"
        />
        <Button
          className={styles.button}
          id="confirmButton"
          onClick={submitHasRead}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

TermsAndConditions.MasterLayout = LoginLayout;
