import { Auth } from 'aws-amplify';

import TextInput from '@/components/text-input';
import { ButtonPrimary } from '@/components/button';
import NotificationService from '@/components/notification-service';
import { LOGIN_STATES } from '@/constants';
import globalStyles from '@/styles/globals.module.scss';
import styles from '../styles.module.scss';

/**
 * @typedef {object} Step1Params
 * @property {string} confirmationCode
 * @property {string} newPassword
 */
/**
 * @param {object} props
 * @param {import('formik').FormikProps<Step1Params>} props.form
 * @param {(string) => void} props.setState
 */
const ResetPassStep1 = ({ form, setState }) => {
  if (!form) {
    return null;
  }

  const { values, touched, errors, handleChange, isSubmitting } = form;

  const resetPassword = async (username, code, newPassword) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);

      NotificationService.info('Password reset');

      return LOGIN_STATES.INITIAL;
    } catch (err) {
      NotificationService.error(err.message);

      return LOGIN_STATES.RESET_PASSWORD;
    }
  };

  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}>Enter Confirmation Code</p>

      <TextInput
        label="Confirmation Code"
        placeholder="Confirmation Code"
        name="confirmationCode"
        value={values.confirmationCode}
        isInvalid={touched.confirmationCode && !!errors.confirmationCode}
        error={errors.confirmationCode}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <TextInput
        label="New Password"
        type="password"
        placeholder="Password"
        name="resetPassword"
        value={values.resetPassword}
        isInvalid={touched.resetPassword && !!errors.resetPassword}
        error={errors.resetPassword}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <ButtonPrimary
        className={styles.submitButton}
        form="loginForm"
        onClick={async () => {
          if (!values.resetPassword) {
            NotificationService.info('Need to enter a new password');
            return;
          }
          const newState = await resetPassword(
            values.email,
            values.confirmationCode,
            values.resetPassword
          );

          setState(newState);
        }}
        disabled={isSubmitting}
      >
        Reset Password
      </ButtonPrimary>
    </div>
  );
};

export default ResetPassStep1;
