import React, { useEffect, useState } from 'react';
import PlusIcon from '@/assets/icons/plus-icon.svg';
import MinusIcon from '@/assets/icons/minus-icon.svg';
import { Spinner } from 'react-bootstrap';
import styles from './pdf-viewer.module.scss';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { loadCSSfromCDN } from '@/utils/loadScript';

/**
 *
 * @param {object} params
 * @param {string} params.filePath - url for the pdf file
 * @param {string} params.height - height for the pdf viewer
 * @param {string} params.width - width for the pdf viewer
 */
export default function PdfViewerReadOnlyTextLayer({
  filePath,
  height,
  width,
}) {
  useEffect(() => {
    loadCSSfromCDN(
      'https://unpkg.com/react-pdf@7.5.0/dist/esm/Page/TextLayer.css'
    );

    loadCSSfromCDN(
      'https://unpkg.com/react-pdf@7.5.0/dist/esm/Page/AnnotationLayer.css'
    );
  }, []);

  const [pageZoom, setPageZoom] = useState(1);

  const [pagesArray, setPagesArray] = useState([]);

  const PAGE_HEIGHT = height || 633;
  const PAGE_WIDTH = width || 564;

  const LoadingSpinner = () => (
    <div className={`${styles.editorSpinnerLoading}`}>
      <div>
        <div className="text-center">
          <Spinner
            className={`${styles.loadingSpinner} my-auto`}
            animation="border"
          />
        </div>
        <div className={`${styles.break}`} />
        <div className="text-center">
          <p className="mx-auto mt-4">Loading Document</p>
        </div>
      </div>
    </div>
  );

  const NoFileMessage = () => (
    <div className={`${styles.noFileMessage}`}>
      <div>
        <div className={`${styles.break}`} />
        <div className="text-center">
          <p className="mx-auto mt-4">No File Selected</p>
        </div>
      </div>
    </div>
  );

  const [loading, setLoading] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    let pagesArray = new Array(numPages).fill(0);

    pagesArray = pagesArray.map((_, index) => index + 1);

    setPagesArray(pagesArray);

    setLoading(false);
  };

  return (
    <>
      <div
        style={{ marginLeft: 10, marginTop: 10 }}
        data-cy="pdf-readonly-document"
      >
        <div>
          <div className={styles.pdfViewerHeaderViewOnly}>
            <div className={styles.zoomOverlay}>
              <button
                className={styles.zoomButton}
                data-element="zoomOutButton"
                aria-label="Zoom out"
                aria-keyshortcuts="Meta+-"
                type="button"
              >
                <PlusIcon onClick={() => setPageZoom(pageZoom + 0.1)} />
              </button>

              <button
                className={styles.zoomButton}
                data-element="zoomInButton"
                aria-label="Zoom in"
                aria-keyshortcuts="Meta+="
                type="button"
              >
                <MinusIcon onClick={() => setPageZoom(pageZoom - 0.1)} />
              </button>
            </div>
          </div>
          {filePath ? (
            <div style={{ overflow: 'auto', height: PAGE_HEIGHT }}>
              <div id="viewer" className="pdfViewer" />

              <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
                {!loading ? (
                  pagesArray.map(pageNumber => (
                    <div
                      key={pageNumber}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Page
                        pageNumber={pageNumber}
                        renderTextLayer={true} // Enable text layer
                        scale={pageZoom}
                        // Add more props as needed
                      />
                    </div>
                  ))
                ) : (
                  <LoadingSpinner />
                )}
              </Document>
            </div>
          ) : (
            <NoFileMessage />
          )}
        </div>
      </div>
    </>
  );
}
