import styles from '../styles.module.scss';

const NoUserPermissions = () => {
  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}>
        No permissions have been assigned to this user
      </p>

      <p className={styles.loginLabel}>
        Ask an administrator to add you to a group
      </p>
    </div>
  );
};

export default NoUserPermissions;
