import { Auth } from 'aws-amplify';

import TextInput from '@/components/text-input';
import { ButtonPrimary, ButtonLink } from '@/components/button';
import NotificationService from '@/components/notification-service';
import { LOGIN_STATES } from '@/constants';
import globalStyles from '@/styles/globals.module.scss';
import styles from '../styles.module.scss';

/**
 * @typedef {object} TwoFactorParams
 * @property {string} verificationCode
 */
/**
 * @param {object} props
 * @param {import('formik').FormikProps<TwoFactorParams>} props.form
 * @param {(string) => void} props.setState
 * @param {(string, string) => void} props.logIn
 * @param {User} props.mostRecentUser
 */
const Sms2faForm = ({ form, setState, logIn, mostRecentUser }) => {
  if (!form) {
    return null;
  }

  const { values, touched, errors, handleChange, isSubmitting } = form;

  const confirmSignIn = async code => {
    try {
      await Auth.confirmSignIn(mostRecentUser, code);

      return logIn();
    } catch (error) {
      NotificationService.error(error.message);

      return LOGIN_STATES.SMS_2FA;
    }
  };

  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}>Enter Verification Code</p>

      <TextInput
        label="Verification Code"
        placeholder="Verification Code"
        name="verificationCode"
        value={values.verificationCode}
        isInvalid={touched.verificationCode && !!errors.verificationCode}
        error={errors.verificationCode}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <ButtonPrimary
        className={styles.submitButton}
        form="loginForm"
        onClick={async () => {
          const newState = await confirmSignIn(values.verificationCode);
          setState(newState);
        }}
        disabled={isSubmitting}
      >
        Verify
      </ButtonPrimary>

      <div className={styles.formActions}>
        <ButtonLink
          id="backButton"
          className={styles.leftButtonLink}
          onClick={() => {
            setState(LOGIN_STATES.INITIAL);
          }}
        >
          Back
        </ButtonLink>

        <ButtonLink
          onClick={() => {
            logIn(values.email, values.password);
          }}
          className={styles.rightButtonLink}
        >
          Resend Verification
        </ButtonLink>
      </div>
    </div>
  );
};

export default Sms2faForm;
