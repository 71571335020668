import TextInput from '@/components/text-input';
import { ButtonPrimary } from '@/components/button';
import globalStyles from '@/styles/globals.module.scss';
import { useState } from 'react';
import { useModal } from '@/hooks';
import styles from '../styles.module.scss';
import { TermsAndConditionsModal } from '../terms-and-conditions-modal';

/**
 * @typedef {object} NewNameParams
 * @property {string} firstName
 * @property {string} lastName
 */
/**
 * @param {object} props
 * @param {import('formik').FormikProps<NewNameParams>} props.form
 */
const EnterNameForm = ({ form }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const modal = useModal();

  if (!form) {
    return null;
  }

  const { values, touched, errors, handleChange, isSubmitting } = form;

  const submitDisabled =
    isSubmitting || !termsAccepted || !values.firstName || !values.lastName;

  return (
    <div className={styles.loginForm}>
      <p className={styles.loginLabel}> Enter Your Name</p>

      <TextInput
        label="First Name"
        placeholder="Enter First Name"
        name="firstName"
        value={values.firstName}
        isInvalid={touched.firstName && !!errors.firstName}
        error={errors.firstName}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <TextInput
        label="Last Name"
        placeholder="Enter Last Name"
        name="lastName"
        value={values.lastName}
        isInvalid={touched.lastName && !!errors.lastName}
        error={errors.lastName}
        onChange={handleChange}
        containerClassName={globalStyles.inputContainer}
      />

      <div className={styles.termsAcceptedContainer}>
        <input
          type="checkbox"
          className={styles.termsAcceptedCheckbox}
          onChange={e => setTermsAccepted(e.target.checked)}
          checked={termsAccepted}
        />
        <div style={{ padding: '0 .5rem 0 .5rem' }}>
          I have agreed to{' '}
          <span
            className={styles.termsAcceptedText}
            onClick={() =>
              modal.show(TermsAndConditionsModal, {
                hide: () => setTermsAccepted(true),
              })
            }
          >
            Terms & Conditions
          </span>
        </div>
      </div>

      <ButtonPrimary
        className={styles.submitButton}
        type="submit"
        form="loginForm"
        disabled={submitDisabled}
      >
        Set
      </ButtonPrimary>
    </div>
  );
};

export default EnterNameForm;
